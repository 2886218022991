<template>
  <div>
    <validation-observer
      ref="scheduledEventExceptionForm"
      #default="{invalid}"
    >
      <b-container class="small-pl full-width">
        <b-row>
          <b-col cols="12">
            <b-alert
              variant="warning"
              show
            >
              <h4 class="alert-heading">
                You are about to cancel one time event on: {{ recurringEventDate | formatDateReturnDayAndMonthOnly }} with {{ visitors.length }} attendants
              </h4>
            </b-alert>

          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">

            <template
              v-for="(visitor,index) in visitors"
            >
              <b-card
                :key="index"
                class="contact-list-card"
              >
                <table
                  class="b-table"
                  style="width:100%;"
                >
                  <tbody>
                    <tr class="contact-list-card-row">
                      <!--
                    <td
                      style="width:250px;"
                      @click.self="toggleRecipient(visitor.id)"

                    >
                      <b-form-checkbox
                        :id="visitor.id + ''"
                        v-model="recipients"
                        :value="visitor.id"
                      >
                        Send notification
                      </b-form-checkbox>
                    </td>
                    -->
                      <td
                        class="contact-list-card-badge"
                      >
                        <b-avatar
                          variant="primary"
                          :text="visitor.first_name.charAt(0) + visitor.last_name.charAt(0)"
                          class="mr-25"
                        />
                      </td>
                      <td
                        class="contact-list-card-name"
                      >
                        {{ visitor.first_name }}
                        {{ visitor.last_name }}
                      </td>
                      <td class="contact-list-card-phone">
                        <a :href="`tel:${visitor.phone}`">
                          <span>
                            <feather-icon icon="SmartphoneIcon" />
                            {{ visitor.phone }}
                          </span>
                        </a>
                      </td>
                      <td class="contact-list-card-email">
                        <a :href="`mailto:${visitor.email}`">
                          <span>
                            <feather-icon icon="MailIcon" />
                            {{ visitor.email }}
                          </span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-card>
            </template>

          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-show="recipients.length > 0"
            cols="12 mb-2 mt-2"
          >
            <h4>
              Compose your message for {{ returnRecipientsCountAndRecipientPlural() }}
            </h4>
          </b-col>

          <b-col md="6">
            <b-card>
              <b-card-header class="small-pl">
                <h4>How do you want to notify them?</h4>
              </b-card-header>
              <b-card-body class="small-pl">
                <b-row>
                  <b-col sm="12">
                    <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="5"
                      content-cols-sm
                      content-cols-lg="4"
                      label="Send notification via Text"
                      label-for="sendSMS"
                    >
                      <b-form-checkbox
                        v-model="sendSMS"
                        checked="false"
                        name="sendSMS"
                        switch
                        inline
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12">
                    <div
                      v-if="sendSMS"
                      :class="[{'mb-2':sendSMS && sendEmail}]"
                    >
                      <validation-provider
                        #default="{ errors }"
                        ref="sms_body"
                        name="Text message body"
                        vid="name"
                        rules="required"
                      >
                        <b-form-textarea
                          v-model="sms_body"
                          rows="3"
                          max-rows="8"
                          placeholder="Text message body"
                          :state="errors.length > 0 ? false:null"
                          class="mt-1"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                  </b-col>
                  <b-col
                    sm="12"
                    :class="[{'mt-1' : sendSMS}]"
                  >
                    <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="5"
                      content-cols-sm
                      content-cols-lg="4"
                      label="Send notification via Email"
                      label-for="sendEmail"
                    >
                      <b-form-checkbox
                        v-model="sendEmail"
                        checked="false"
                        name="sendEmail"
                        switch
                        inline
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <div v-if="sendEmail">
                      <validation-provider
                        #default="{ errors }"
                        ref="email_body"
                        name="E-mail message body"
                        vid="name"
                        rules="required"
                      >
                        <b-form-textarea
                          v-model="email_body"
                          rows="3"
                          max-rows="8"
                          placeholder="E-mail message body"
                          :state="errors.length > 0 ? false:null"
                          class="mt-1"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-show="recipients.length === 0">
                  <b-col cols="12">
                    <validation-provider
                      ref="recipients"
                      #default="{ errors }"
                      name="Recipients"
                      vid="recipients"
                    >
                      <small
                        v-show="invalid && errors && errors.length"
                        class="text-danger"
                      >
                        you haven't selected any recipients. If you don't want to notify anybody, just uncheck email and text notifiction switches
                      </small>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card-body>
              <b-card-footer class="padding-0">
                <b-row md="12">
                  <b-col cols="4">
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      type="reset"
                      class="mt-2"
                      @click.prevent="goBackToLocation"
                    >
                      Don't cancel
                    </b-button>
                  </b-col>
                  <b-col cols="8 align-right-column">
                    <b-spinner
                      v-if="isSubmitting"
                      class="mt-1"
                      label="Loading..."
                    />
                    <b-button
                      v-show="!isSubmitting"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mt-2"
                      :disabled="isSubmitting"
                      @click="submitForm"
                    >
                      {{ submitText }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-footer>
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card v-if="sendEmail || sendSMS">
              <div class="h4 pt-2 mb-2">
                Merge Tags
              </div>
              <div class="mb-2">
                Insert personalized information in your messages.
              </div>
              <MergeTagsList />
            </b-card>
          </b-col>
        </b-row>

      </b-container>
    </validation-observer>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider, Validator } from 'vee-validate'
import { required } from '@validations'
import {
  BSpinner,
  BAvatar,
  BAlert,
  BFormCheckbox,
  BCardHeader,
  BCardFooter,
  BFormGroup,
  BFormTextarea,
  VBToggle,
  BBadge,
  BCard,
  BRow,
  BCol,
  BButton,
  BContainer,
  BCardBody,
} from 'bootstrap-vue'
import MergeTagsList from '@/views/components/merge-tags-list/MergeTagsList.vue'
import AccountService from '@/services/AccountService'
import Ripple from 'vue-ripple-directive'
import { handleValidationError } from '@/utils/helpers'

export default {
  components: {
    BSpinner,
    BAlert,
    BAvatar,
    BFormCheckbox,
    BCard,
    BFormGroup,
    BFormTextarea,
    BRow,
    BCol,
    BCardHeader,
    BCardFooter,
    BBadge,
    BButton,
    BContainer,
    BCardBody,
    ValidationProvider,
    ValidationObserver,
    MergeTagsList,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  props: ['locationId', 'recurringEventId', 'recurringEventDate'],
  data() {
    return {
      visitors: [],
      sendSMS: false,
      sendEmail: false,
      isSubmitting: false,
      recipients: [],
      email_body: '',
      sms_body: '',
      required,
    }
  },
  computed: {
    submitText() {
      const self = this
      if (self.sendSMS || self.sendEmail) {
        return `Cancel Service & Send to ${this.returnRecipientsCountAndRecipientPlural()}`
      } return 'Cancel Service without sending'
    },
  },
  created() {
    AccountService.getScheduledVisitorsForRecurringEvent(
      this.recurringEventId,
      this.recurringEventDate,
    ).then(response => {
      this.visitors = response.data.data
      this.visitors.map(visitor => this.toggleRecipient(visitor.id))
    })
  },
  methods: {
    showCancelWithoutSendingConfirmationModal() {
      return this.$bvModal
        .msgBoxConfirm('Are you sure you want to cancel this event without notifying attendants?', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
    },
    validateThenCreateRecurringException() {
      this.$refs.scheduledEventExceptionForm.validate()
        .then(async success => {
          if (success) {
            try {
              await this.createRecurringException()
            } catch (error) {
              handleValidationError(error, this)
            } finally {
              this.isSubmitting = false
            }
          } else {
            this.isSubmitting = false
          }
        })
    },
    async createRecurringException() {
      await AccountService.createRecurringEventException({
        recurring_event_id: this.recurringEventId,
        date: this.recurringEventDate,
        send_sms: this.sendSMS,
        send_email: this.sendEmail,
        email_body: this.email_body,
        sms_body: this.sms_body,
        recipients: this.recipients,
      }).then(response => {
        this.isSubmitting = false
        this.$router.push({
          name: 'edit-location',
          params: { locationId: this.locationId },
        })
      })
    },
    async submitForm() {
      this.isSubmitting = true
      if (!this.sendSMS && !this.sendEmail) {
        const showConfirmationWithoutSending = this.showCancelWithoutSendingConfirmationModal()
        showConfirmationWithoutSending.then(value => {
          if (value) {
            this.createRecurringException()
          } else {
            this.isSubmitting = false
          }
        })
      } else {
        await this.validateThenCreateRecurringException()
      }
    },
    toggleRecipient(id) {
      if (!this.recipients.includes(id)) {
        this.recipients.push(id)
      } else {
        const index = this.recipients.indexOf(id)
        if (index !== -1) {
          this.recipients.splice(index, 1)
        }
      }
    },
    returnRecipientsCountAndRecipientPlural() {
      const recipients = this.recipients.length
      const label = recipients <= 1 ? 'recipient' : 'recipients'
      return `${recipients} ${label}`
    },
    goBackToLocation() {
      this.$router.push({ name: 'edit-location', params: { locationId: this.locationId } })
    },
  },
}
</script>
<style scoped>
.padding-0 {
  padding: 0 !important;
}
.inverted-badge {
  background: transparent;
  border: 1px solid var(--purple);
  padding: 0.75rem;
  color: var(--purple);
}
.contact-details-phonemail > * {
  margin-right: 25px;
}
.top-pagination-col ul {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}

.bottom-pagination-col ul {
  justify-content: center;
}

.list-subscribe-col label {
  font-size: 1rem;
}

.contact-list-card {
  margin-bottom: 1rem;

}
.contact-list-card .card-body{
  padding:0;
}
.contact-list-card .card-body td{
  padding: 1.5rem;
}
.td-wrap .custom-select {
  max-width: 190px;
}
.card-title.no-bottom{
  margin-bottom:0;
}
.small-pl{
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.align-right-column{
  justify-content: flex-end;
  display: flex;
}
.full-width{
  max-width:100%;
}
.contact-list-card-badge {
  width: 50px;
}
.contact-list-card-name {
  text-align: left;
  width: 20%;
}
.contact-list-card-phone {
  text-align: left;
  width: 30%;
}
@media (max-width: 767px) {
  .contact-list-card-row {
    display: block;
  }
  .contact-list-card-name {
    width: 100%;
  }
  .contact-list-card-phone, .contact-list-card-email {
    display: block;
    width: 100%;
  }
  .contact-list-card .card-body .contact-list-card-badge {
    padding-right: 5px;
  }
  .contact-list-card .card-body .contact-list-card-name {
    padding-left: 5px;
  }
  .contact-list-card .card-body .contact-list-card-phone {
    padding-top: 0;
    padding-bottom: 10px;
  }
  .contact-list-card .card-body .contact-list-card-email {
    padding-top: 0;
  }
}
</style>
