<template>
  <b-row class="match-height">
    <b-col>
      <div class="mb-1">
        <strong v-pre>{{ first_name }}</strong><br>
        Contact's First Name (ex: "John")
      </div>

      <div class="mb-1">
        <strong v-pre>{{ last_name }}</strong><br>
        Contact's Last Name (ex: "Smith")
      </div>

      <div class="mb-1">
        <strong v-pre>{{ email }}</strong><br>
        Contact's Email (ex: "example@gmail.com")
      </div>

      <div class="mb-1">
        <strong v-pre>{{ phone }}</strong><br>
        Contact's phone number (e.g. "202-555-0147")
      </div>

      <div class="mb-1">
        <strong v-pre>{{ day }}</strong><br>
        Day of the scheduled visit (e.g. "{{ day }}")
      </div>

      <div class="mb-1">
        <strong v-pre>{{ time }}</strong><br>
        Time of the scheduled visit (e.g. "9:30a")
      </div>

      <div class="mb-1">
        <strong v-pre>{{ dow }}</strong><br>
        Day of week of the scheduled visit (e.g. "Wed")
      </div>

      <div class="mb-1">
        <strong v-pre>{{ campus_name }}</strong><br>
        Campus name (e.g. "{{ locationName }}")
      </div>

      <div class="mb-1">
        <strong v-pre>{{ campus_location }}</strong><br>
        Campus address (e.g. "{{ locationAddress }}")
      </div>

      <div class="mb-1">
        <strong v-pre>{{ church_name }}</strong><br>
        Name of the Church (e.g. "{{ churchName }}")
      </div>

      <div class="mb-1">
        <strong v-pre>{{ next_service }}</strong><br>
        Date of the next service in the campus (e.g. "{{ nextService }} at 1:30p")
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import dayjs from 'dayjs'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
  },
  data() {
    const user = store.getters['auth/authUser']
    let locationData
    if (user.account.locations.length) {
      locationData = {
        locationName: user.account.locations[0].name,
        locationAddress: user.account.locations[0].address || '123 Street',
      }
    } else {
      locationData = {
        locationName: 'Downtown',
        locationAddress: '123 Street',
      }
    }

    return {
      ...locationData,
      churchName: user.account.settings.general.church_name,
      day: `${dayjs().add(1, 'days').format('MMM D YYYY')}`,
      nextService: dayjs().add(3, 'days').format('MMM D YYYY'),
    }
  },
}
</script>
